import "./src/styles/global.css"
import '@fontsource/pt-sans/400.css';
import '@fontsource/pt-sans/700.css';

function initGTMOnEvent(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
}

function initGTM() {
  if (window.gtmDidInit) {
    return false;
  }

  window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.

  var script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GA_TRACKING_ID}`;

  script.onload = function () {
    window.dataLayer.push({
      // ensure PageViews are always tracked
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      'gtm.uniqueEventId': 0,
    });
  };

  document.head.appendChild(script);
}

const initGHLLeadForm = () => {
  if (window.ghlFormDidInit) {
    return false;
  }

  window.ghlFormDidInit = true;

  // Delay Load GHL Form
  const formContainer = document.querySelector("#form-container")

  if (formContainer) {
    var formGHLScript = document.createElement('script');
    formGHLScript.type = 'text/javascript';
    formGHLScript.async = true;
    formGHLScript.src = `https://link.msgsndr.com/js/form_embed.js`;
    document.head.appendChild(formGHLScript);

    var formGHLIframe = document.createElement('iframe');
    formGHLIframe["src"] = "https://api.leadconnectorhq.com/widget/form/ywOq4eToIuYlvrfB7MW9"
    formGHLIframe["style"] = "width:100%;height:100%;border:none;border-radius:4px"
    formGHLIframe["id"] = "inline-ywOq4eToIuYlvrfB7MW9"
    formGHLIframe["data-layout"] = "{'id':'INLINE'}"
    formGHLIframe["data-trigger-type"] = "alwaysShow"
    formGHLIframe["data-trigger-value"] = ""
    formGHLIframe["data-activation-type"] = "alwaysActivated"
    formGHLIframe["data-activation-value"] = ""
    formGHLIframe["data-deactivation-type"] = "neverDeactivate"
    formGHLIframe["data-deactivation-value"] = ""
    formGHLIframe["data-form-name"] = "New Lead Form"
    formGHLIframe["data-height"] = "628"
    formGHLIframe["data-layout-iframe-id"] = "inline-ywOq4eToIuYlvrfB7MW9"
    formGHLIframe["data-form-id"] = "ywOq4eToIuYlvrfB7MW9"
    formGHLIframe["title"] = "New Lead Form"

    formContainer.appendChild(formGHLIframe);
  }
}

export const onClientEntry = () => {
  document.onreadystatechange = function () {
    if (document.readyState !== 'loading') {
      setTimeout(initGTM, 3000);
      setTimeout(initGHLLeadForm, 2000);
    }
  };
  document.addEventListener('scroll', initGTMOnEvent);
  document.addEventListener('mousemove', initGTMOnEvent);
  document.addEventListener('touchstart', initGTMOnEvent);
};